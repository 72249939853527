body {
  /* font-family: "Roboto", arial, sans-serif; */
  font-weight: 200;
  font-size: 16px;
  line-height: 1.8;
  overflow-x: hidden;
  position: relative;
}

::-moz-selection {
  color: #fff;
  background: #ff0000;
}

::selection {
  color: #fff;
  background: #ff0000;
}

a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  color: #000;
  font-family: "Playfair Display", times, serif;
}

.font-family-serif {
  font-family: "Playfair Display", times, serif;
}

.font-family-sans-serif {
  font-family: "Roboto", arial, sans-serif;
}

.bg-light {
  background: #f2f4fb !important;
}

.container-fluid {
  max-width: 1600px;
}

.btn {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 50px;
}
.btn.btn-outline-white {
  border: 2px solid #fff;
}
.btn.btn-outline-white:hover {
  border: 2px solid transparent;
  background: #000;
  color: #fff;
}
.btn.btn-outline-white-primary {
  border: 2px solid #fff;
}
.btn.btn-outline-white-primary:hover {
  border: 2px solid transparent;
  background: #ff0000;
  color: #fff;
}
.btn.uppercase {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.2em;
}

.btn,
.form-control {
  outline: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.btn:focus,
.btn:active,
.form-control:focus,
.form-control:active {
  outline: none;
}

.form-control {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  height: 50px;
  border-width: 2px;
}
.form-control:active,
.form-control:focus {
  border-color: #ff0000;
}

textarea.form-control {
  height: inherit;
}

.full-height {
  height: 100vh;
  min-height: 700px;
}

.site-hero {
  background-size: cover;
  height: 100vh;
  min-height: 700px;
  width: 100%;
  position: relative;
}
.site-hero .heading {
  font-family: "Playfair Display", times, serif;
  font-weight: bold;
}
.site-hero .scroll-down {
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #fff;
}
.site-hero.overlay:before {
  background: rgba(0, 0, 0, 0.45);
  content: "";
  position: absolute;
  height: 100vh;
  min-height: 700px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.site-hero.inner-page {
  height: 50vh;
  min-height: 700px;
}
.site-hero.inner-page.overlay:before {
  height: 50vh;
  min-height: 700px;
}

.site-hero-inner {
  height: 100vh;
  min-height: 700px;
}
.site-hero-inner .heading {
  font-size: 80px;
  font-family: "Playfair Display", times, serif;
  color: #fff;
  line-height: 1;
  font-weight: bold;
}

.site-hero-inner .sub-heading {
  font-size: 30px;
  font-weight: 200;
  color: #fff;
  line-height: 1.5;
}

.inner-page .site-hero-inner {
  height: 50vh;
  min-height: 700px;
}

.page-inside .site-hero-inner,
.page-inside {
  height: 70vh;
  min-height: 500px;
}

.page-inside.overlay:before {
  height: 70vh;
  min-height: 500px;
}

.menu-open .site-menu-toggle span {
  background: #000;
}

.site-menu-toggle {
  display: none;
  top: 0;
  float: right;
  width: 40px;
  height: 45px;
  position: relative;
  margin: 0px auto;
  z-index: 200;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  margin-right: 40px;
}
.site-menu-toggle span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.site-menu-toggle span {
  background: #000;
}

.site-menu-toggle span:nth-child(1) {
  top: 0px;
}
.site-menu-toggle span:nth-child(2) {
  top: 10px;
}
.site-menu-toggle span:nth-child(3) {
  top: 20px;
}
.site-menu-toggle.open span:nth-child(1) {
  top: 13px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}
.site-menu-toggle.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}
.site-menu-toggle.open span:nth-child(3) {
  top: 13px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.section {
  padding: 7em 0;
}

.bg-pattern {
  background: #e9ecef;
}

.slider-section {
  position: relative;
}

.blog-post-entry {
  position: relative;
}

.footer-section {
  background: #1a1a1a;
  color: #fff;
}
.footer-section a {
  color: rgba(255, 255, 255, 0.7);
}
.footer-section a:hover {
  color: #fff;
}
.footer-section p {
  color: rgba(255, 255, 255, 0.5);
}
.footer-section .bordertop {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 20px;
}
.footer-section .contact-info span.d-block {
  font-style: italic;
  color: #fff;
}
.footer-section .social a {
  font-size: 18px;
  padding: 10px;
}
.footer-section .link li {
  margin-bottom: 10px;
}

.footer-newsletter .form-group {
  position: relative;
}

.footer-newsletter .form-control {
  background: none;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0;
  color: #fff;
}
.footer-newsletter .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.2);
  font-style: italic;
}
.footer-newsletter .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.2);
  font-style: italic;
}
.footer-newsletter .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(255, 255, 255, 0.2);
  font-style: italic;
}
.footer-newsletter .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.2);
  font-style: italic;
}
.footer-newsletter .form-control:active,
.footer-newsletter .form-control:focus {
  border-bottom: 1px solid white;
}

.footer-newsletter button[type="submit"] {
  background: none;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
}

.side-box,
.sidebar-search {
  padding: 30px;
  background: #fff;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}
.side-box .heading,
.sidebar-search .heading {
  font-size: 18px;
  margin-bottom: 30px;
  font-family: "Roboto", arial, sans-serif;
}

.post-list li {
  margin-bottom: 20px;
}
.post-list li a > div {
  margin-top: -10px;
}
.post-list li a .meta {
  font-size: 13px;
  color: #adb5bd;
}
.post-list li a .image {
  width: 150px;
}
.post-list li a h3 {
  font-size: 16px;
}
.post-list li:last-child {
  margin-bottom: 0;
}

.sidebar-search .form-group {
  position: relative;
  margin-bottom: 0;
}

.sidebar-search .icon-search {
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sidebar-search .search-input {
  border-color: #dee2e6;
  padding-left: 40px;
  border-radius: 0px;
}
.sidebar-search .search-input:focus,
.sidebar-search .search-input:active {
  border-color: #343a40;
}

.contact-section .contact-info p {
  font-family: "Playfair Display", times, serif;
  font-size: 30px;
  margin-bottom: 30px;
}
.contact-section .contact-info p .d-block {
  font-size: 14px;
  letter-spacing: 0.2em;
  font-weight: bold;
  font-family: "Roboto", arial, sans-serif;
  text-transform: uppercase;
}

.line-height-1-2 {
  line-height: 1.2;
}

/* Mouse Animation */
.mouse {
  width: 100px;
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: 140px;
  z-index: 1;
  -webkit-transform: translateX(-25%);
  -ms-transform: translateX(-25%);
  transform: translateX(-25%);
}

.mouse-icon {
  width: 25px;
  height: 45px;
  border: 2px solid white;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  text-align: center;
}

.mouse-wheel {
  height: 6px;
  margin: 2px auto 0;
  display: block;
  width: 3px;
  background-color: white;
  border-radius: 50%;
  -webkit-animation: 1.6s ease infinite wheel-up-down;
  -moz-animation: 1.6s ease infinite wheel-up-down;
  animation: 1.6s ease infinite wheel-up-down;
}

@-webkit-keyframes wheel-up-down {
  0% {
    margin-top: 2px;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    margin-top: 20px;
    opacity: 0;
  }
}

@-moz-keyframes wheel-up-down {
  0% {
    margin-top: 2px;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    margin-top: 20px;
    opacity: 0;
  }
}

@keyframes wheel-up-down {
  0% {
    margin-top: 2px;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    margin-top: 20px;
    opacity: 0;
  }
}

.field-icon-wrap {
  position: relative;
}
.field-icon-wrap .form-control {
  position: relative;
  padding-right: 40px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #a6a6a6;
}
.field-icon-wrap .icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
  z-index: 2;
}
.field-icon-wrap select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
}

.block-32 {
  background: #fff;
  padding: 30px;
  -webkit-box-shadow: 0 2px 80px -10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 80px -10px rgba(0, 0, 0, 0.2);
  width: 100%;
}
.block-32 .field-icon-wrap {
  position: relative;
}
.block-32 .field-icon-wrap .form-control {
  position: relative;
  padding-right: 40px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #a6a6a6;
}
.block-32 .field-icon-wrap .icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
  z-index: 2;
}
.block-32 .field-icon-wrap select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
}

.block-2 {
  margin-bottom: 50px;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -ms-transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
}
.block-2:hover .back,
.block-2.hover .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.block-2:hover .front,
.block-2.hover .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.block-2,
.block-2 .front,
.block-2 .back {
  width: 100%;
  height: 427px;
}
.block-2 .flipper {
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -moz-transition: 0.6s;
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transition: 0.6s;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}
.block-2 .front,
.block-2 .back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 4px;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -webkit-transform: rotateY(0deg);
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -moz-transform: rotateY(0deg);
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -o-transform: rotateY(0deg);
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  -ms-transform: rotateY(0deg);
  transition: 0.6s;
  transform-style: preserve-3d;
  transform: rotateY(0deg);
  position: absolute;
  top: 0;
  left: 0;
}
.block-2 .front {
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  background: lightgreen;
  z-index: 2;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.block-2 .front:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: -moz-linear-gradient(
    top,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: -webkit-linear-gradient(
    top,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    color-stop(18%, transparent),
    color-stop(99%, rgba(0, 0, 0, 0.8)),
    to(rgba(0, 0, 0, 0.8))
  );
  background: -o-linear-gradient(
    top,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 );
}
.block-2 .front .box {
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
  bottom: 20px;
}
.block-2 .front .box h2,
.block-2 .front .box p {
  color: #fff;
  margin: 0;
  padding: 0;
  line-height: 1.5;
}
.block-2 .front .box h2 {
  font-size: 20px;
}
.block-2 .front .box p {
  font-size: 12px;
}
.block-2 .back {
  background: #fff;
  -webkit-box-shadow: 0 0 70px -10px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 70px -10px rgba(0, 0, 0, 0.4);
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.block-2 .back p {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 20px;
  font-size: 18px;
}
.block-2 .author {
  bottom: 0;
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
}
.block-2 .author .image {
  width: 40px;
}
.block-2 .author .image img {
  border-radius: 50%;
  max-width: 100%;
}
.block-2 .author .position {
  display: block;
  font-size: 12px;
}
@media (max-width: 991.98px) {
  .block-2 .back {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  .block-2 .front {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
}

.text-black {
  color: #000 !important;
}

.check-availabilty {
  margin-top: -170px;
  position: relative;
}
.check-availabilty .block-32 {
  background: #fff;
  border-radius: 10px;
}

.room {
  position: relative;
  display: block;
}
.room .img-wrap {
  position: relative;
  overflow: hidden;
}
.room .img-wrap img {
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  margin-bottom: 0 !important;
}
.room:hover .img-wrap img,
.room:focus .img-wrap img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.letter-spacing-1 {
  letter-spacing: 0.1em;
}

.text-opacity-8 {
  opacity: 0.8;
}

.text-opacity-7 {
  opacity: 0.7;
}

.text-opacity-6 {
  opacity: 0.6;
}

.text-opacity-5 {
  opacity: 0.5;
}

.letter-spacing-2 {
  letter-spacing: 0.2em;
}

.bg-image {
  background-size: cover;
  background-attachment: fixed;
}

.bg-image-2 {
  background-size: cover;
  background-position: center center;
}

.food-menu-tabs {
  text-align: center;
}
.food-menu-tabs .nav-tabs {
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  border-bottom: none;
}
.food-menu-tabs .nav-tabs li {
  display: inline-block;
}
.food-menu-tabs .nav-tabs li a {
  border: none;
  background: none;
  font-size: 1.2rem;
  font-weight: bold;
  position: relative;
  display: block;
}
.food-menu-tabs .nav-tabs li a:before {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  content: "";
  position: absolute;
  bottom: 0;
  left: 16px;
  right: 20px;
  height: 2px;
  background: transparent;
}
.food-menu-tabs .nav-tabs li a.active {
  border: none;
  background: none;
  color: #fff;
}
.food-menu-tabs .nav-tabs li a.active:before {
  background: #fff;
}

.custom-caption {
  font-size: 14px;
  letter-spacing: 0.2em;
}

.bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
}
.bg-image.overlay {
  position: relative;
}
.bg-image.overlay > .container {
  position: relative;
  z-index: 3;
}
.bg-image.overlay:before {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: rgba(0, 0, 0, 0.6);
}

.img-absolute {
  position: absolute;
  bottom: -100px;
  right: -100px;
}
.img-absolute img {
  max-width: 250px;
  border-radius: 50%;
  border: 10px solid #f2f4fb;
}
@media (max-width: 991.98px) {
  .img-absolute {
    right: 0px;
  }
  .img-absolute img {
    max-width: 160px;
  }
}

.custom-breadcrumbs {
  list-style: none;
  padding: 0;
  margin: 0;
}
.custom-breadcrumbs li {
  display: inline-block;
  color: #fff;
  margin: 0 4px;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.1em;
  font-weight: bold;
}
.custom-breadcrumbs li a {
  font-weight: normal;
}
.custom-breadcrumbs li a:hover {
  color: #fff;
}

.timeline-item {
  padding: 3em 2em 2em;
  position: relative;
  border-left: 2px solid #dee2e6;
}
.timeline-item::before {
  content: attr(date-is);
  position: absolute;
  left: 2em;
  font-weight: bold;
  top: 1em;
  display: block;
  font-weight: 700;
  font-size: 0.785rem;
}
.timeline-item::after {
  width: 10px;
  height: 10px;
  display: block;
  top: 1em;
  position: absolute;
  left: -6px;
  border-radius: 10px;
  content: "";
  border: 2px solid #dee2e6;
  background: white;
}
.timeline-item:last-child {
  -webkit-border-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(60%, #dee2e6),
      to(rgba(222, 226, 230, 0))
    )
    1 100%;
  -webkit-border-image: -webkit-linear-gradient(
      top,
      #dee2e6 60%,
      rgba(222, 226, 230, 0)
    )
    1 100%;
  -o-border-image: -o-linear-gradient(top, #dee2e6 60%, rgba(222, 226, 230, 0))
    1 100%;
  border-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(60%, #dee2e6),
      to(rgba(222, 226, 230, 0))
    )
    1 100%;
  border-image: linear-gradient(to bottom, #dee2e6 60%, rgba(222, 226, 230, 0))
    1 100%;
}

.custom-pagination {
  text-align: center;
}
.custom-pagination ul {
  padding: 0;
  margin: 0;
}
.custom-pagination ul li {
  display: inline-block;
}
.custom-pagination ul li a {
  background: #fff;
  border-radius: 50%;
  border: 1px solid transparent;
}
.custom-pagination ul li a:hover {
  border: 1px solid #d9d9d9;
  -webkit-box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.1);
}
.custom-pagination ul li a,
.custom-pagination ul li span {
  display: inline-block;
  width: 47px;
  margin: 2px;
  height: 47px;
  font-size: 1.2rem;
  line-height: 47px;
}
.custom-pagination ul li.active span {
  background: #ff0000;
  color: #fff;
  border-radius: 50%;
}

.pb_navbar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 50px 0;
}
.pb_navbar .navbar-toggler:active,
.pb_navbar .navbar-toggler:focus {
  outline: none;
}
.pb_navbar .navbar-brand {
  font-family: "Playfair Display", times, serif;
  font-size: 30px;
}
.pb_navbar > .container {
  position: relative;
}
.pb_navbar .navbar-toggler {
  border: none;
  cursor: pointer;
  padding-right: 0;
}
.pb_navbar .navbar-toggler i {
  font-size: 30px;
}
.pb_navbar ul > li.cta-btn > a > span {
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
@media (max-width: 991.98px) {
  .pb_navbar ul > li.cta-btn > a > span {
    border: none;
    background: #ff0000;
    color: #fff;
  }
}
.pb_navbar .nav-link {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
@media (max-width: 1199.98px) {
  .pb_navbar .nav-link {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
@media (max-width: 991.98px) {
  .pb_navbar .nav-link {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media (max-width: 767.98px) {
  .pb_navbar .nav-link {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media (max-width: 991.98px) {
  .pb_navbar .navbar-nav {
    border-radius: 0px;
  }
  .pb_navbar .navbar-nav a {
    color: #000;
  }
}
@media (max-width: 991.98px) {
  .pb_navbar {
    background: #fff;
    padding: 15px 15px;
  }
  .pb_navbar .navbar-brand {
    color: #000 !important;
    font-family: "Playfair Display", times, serif;
    margin-left: 15px;
  }
  .pb_navbar .navbar-nav {
    background: #fff !important;
  }
  .pb_navbar .navbar-toggler {
    color: black !important;
    border-color: black !important;
    cursor: pointer;
  }
  .pb_navbar ul li a {
    color: #000 !important;
  }
}
.pb_navbar.scrolled {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  margin-top: -130px;
  background: #fff;
  padding: 0px 0;
}
@media (max-width: 991.98px) {
  .pb_navbar.scrolled .navbar-nav {
    background: none;
    border-radius: 0px;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
}
@media (max-width: 767.98px) {
  .pb_navbar.scrolled .navbar-nav {
    background: none;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.pb_navbar.scrolled .navbar-toggler {
  border: none;
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  padding-right: 0;
}
.pb_navbar.scrolled .nav-link {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pb_navbar.scrolled.awake {
  margin-top: 0px;
  -webkit-transition: 0.3s all ease-out;
  -o-transition: 0.3s all ease-out;
  transition: 0.3s all ease-out;
}
.pb_navbar.scrolled.sleep {
  -webkit-transition: 0.3s all ease-out;
  -o-transition: 0.3s all ease-out;
  transition: 0.3s all ease-out;
}
.pb_navbar.scrolled.pb_scrolled-light {
  background: #fff !important;
  -webkit-box-shadow: 0px 1px 10px -2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 1px 10px -2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 10px -2px rgba(0, 0, 0, 0.15);
}
.pb_navbar.scrolled.pb_scrolled-light .navbar-brand {
  color: #000;
  font-family: "Playfair Display", times, serif;
}
@media (max-width: 991.98px) {
  .pb_navbar.scrolled.pb_scrolled-light .navbar-nav {
    background: none;
    border-radius: 0px;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
}
.pb_navbar.scrolled.pb_scrolled-light ul li a {
  color: #212529 !important;
}
.pb_navbar.scrolled.pb_scrolled-light ul li a:hover,
.pb_navbar.scrolled.pb_scrolled-light ul li a:active,
.pb_navbar.scrolled.pb_scrolled-light ul li a:focus {
  color: #6c757d !important;
}
.pb_navbar.scrolled.pb_scrolled-light ul li a.active {
  color: #ff0000 !important;
}
.pb_navbar.scrolled.pb_scrolled-light ul li.cta-btn > a > span {
  border: none;
  background: #ff0000;
  color: #fff;
}

.pb_offcanvas-nav_v1 {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #000;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.pb_offcanvas-nav_v1.active {
  opacity: 1;
  visibility: visible;
}
.pb_offcanvas-nav_v1 .pb_main-nav {
  padding-top: 50px;
  padding-right: 50px;
  float: right;
  text-align: right;
  font-size: 30px;
  height: 100vh;
  overflow-y: auto;
}
.pb_offcanvas-nav_v1 .pb_main-nav li {
  list-style: none;
  line-height: 1.5;
  margin-bottom: 10px;
}
.pb_offcanvas-nav_v1 .pb_main-nav li a {
  color: #fff;
}
.pb_offcanvas-nav_v1 .pb_main-nav li a.active {
  text-decoration: underline;
}

.pb_offcanvas-header_v1 {
  padding-top: 40px;
  z-index: 2;
  position: absolute;
  width: 100%;
}
.pb_offcanvas-header_v1 > .container {
  position: relative;
}
.pb_offcanvas-header_v1 .pb_site-name {
  position: absolute;
  left: 15px;
}
.pb_offcanvas-header_v1 .pb_toggle-menu {
  position: absolute;
  right: 0px;
  top: 17px;
}
@media (max-width: 767.98px) {
  .pb_offcanvas-header_v1 .pb_toggle-menu {
    top: 13px;
  }
}

.block-32 {
  background: #fff;
  padding: 30px;
  -webkit-box-shadow: 0 2px 80px -10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 80px -10px rgba(0, 0, 0, 0.2);
  width: 100%;
}
.banner-card {
  margin-top: -170px;
  position: relative;
}
.banner-card .block-32 {
  background: #fff;
  border-radius: 10px;
}
.hp-spotlight {
  display: block;
  float: left;
  margin: -190px 0px 140px;
  position: relative;
  width: 100%;
  z-index: 3;
}
.hp-spotlight .wrapper {
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.news-event .wrapper {
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.news-event {
  padding: 75px 0px 90px;
  height: 200px;
  color: #fff;
  background: #002b32;
  background: -moz-linear-gradient(
    -45deg,
    #002b32 0%,
    #002b32 26%,
    #012c32 28%,
    #042d33 29%,
    #012c32 29%,
    #052e32 34%,
    #032f32 36%,
    #062f33 36%,
    #062f33 38%,
    #052e30 38%,
    #073032 38%,
    #062f31 41%,
    #083133 42%,
    #073130 42%,
    #073130 44%,
    #0d3632 57%,
    #0c352f 58%,
    #0e3731 58%,
    #0e3731 61%,
    #0d362e 61%,
    #113931 66%,
    #0f3830 67%,
    #143d2f 77%,
    #133c2e 79%,
    #153e30 79%,
    #173d2e 86%,
    #163f2f 87%,
    #193f30 88%,
    #17402e 90%,
    #1c432e 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    #002b32 0%,
    #002b32 26%,
    #012c32 28%,
    #042d33 29%,
    #012c32 29%,
    #052e32 34%,
    #032f32 36%,
    #062f33 36%,
    #062f33 38%,
    #052e30 38%,
    #073032 38%,
    #062f31 41%,
    #083133 42%,
    #073130 42%,
    #073130 44%,
    #0d3632 57%,
    #0c352f 58%,
    #0e3731 58%,
    #0e3731 61%,
    #0d362e 61%,
    #113931 66%,
    #0f3830 67%,
    #143d2f 77%,
    #133c2e 79%,
    #153e30 79%,
    #173d2e 86%,
    #163f2f 87%,
    #193f30 88%,
    #17402e 90%,
    #1c432e 100%
  );
  background: linear-gradient(
    135deg,
    #002b32 0%,
    #002b32 26%,
    #012c32 28%,
    #042d33 29%,
    #012c32 29%,
    #052e32 34%,
    #032f32 36%,
    #062f33 36%,
    #062f33 38%,
    #052e30 38%,
    #073032 38%,
    #062f31 41%,
    #083133 42%,
    #073130 42%,
    #073130 44%,
    #0d3632 57%,
    #0c352f 58%,
    #0e3731 58%,
    #0e3731 61%,
    #0d362e 61%,
    #113931 66%,
    #0f3830 67%,
    #143d2f 77%,
    #133c2e 79%,
    #153e30 79%,
    #173d2e 86%,
    #163f2f 87%,
    #193f30 88%,
    #17402e 90%,
    #1c432e 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002b32', endColorstr='#1c432e', GradientType=1 );
  overflow: hidden;
}
.news-event:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 43, 50, 1);
  background: -moz-linear-gradient(
    top,
    rgba(0, 43, 50, 1) 0%,
    rgba(3, 3, 3, 0) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 43, 50, 1)),
    color-stop(100%, rgba(3, 3, 3, 0))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 43, 50, 1) 0%,
    rgba(3, 3, 3, 0) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 43, 50, 1) 0%,
    rgba(3, 3, 3, 0) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 43, 50, 1) 0%,
    rgba(3, 3, 3, 0) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 43, 50, 1) 0%,
    rgba(3, 3, 3, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002b32', endColorstr='#030303', GradientType=0 );
  z-index: 0;
}

.news-left {
  display: block;
  float: left;
  width: 100%;
  max-width: 315px;
  position: relative;
  z-index: 3;
}
.news-left h2 {
  margin: 0px 0px 20px;
  font-family: "latolight";
  font-size: 45px;
  font-weight: 300;
  line-height: 50px;
}
.news-left p a {
  color: #c0cf13;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-decoration: underline;
}
.news-left p a:hover {
  color: #fff;
}
.news-slider {
  display: block;
  float: right;
  width: 63.2%;
  position: relative;
  z-index: 3;
  /* background: #ff0000; */
}
.news-event.hp-spot-wrap {
  overflow: visible;
  z-index: 999;
}
.news-slider .slick-prev,
.news-slider .slick-next {
  display: block;
  position: absolute;
  top: 58%;
  width: 56px;
  height: 56px;
  margin: 0px;
  padding: 0;
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.23);
  outline: none;
  background: transparent;
}
.news-slider .slick-prev {
  left: -400px;
  border-right: none;
}
.news-slider .slick-next {
  left: -344px;
}
.news-slider .slick-prev:before,
.news-slider .slick-next:before {
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  width: 15px;
  height: 24px;
  z-index: 2;
}
.news-slider .slick-prev:before {
  background: url("/images/prev-arrow.png") no-repeat 0px 0px;
}
.news-slider .slick-next:before {
  background: url("/images/next-arrow.png") no-repeat 0px 0px;
}
.news-slider .slick-prev:hover,
.news-slider .slick-next:hover {
  background-color: rgba(255, 255, 255, 0.15);
}
.news-list {
  padding: 2px 2px 4px;
  outline: none;
}
.news-list a {
  display: block;
  padding: 45px;
  margin-right: 30px;
  max-width: 398px;
  height: 307px;
  font-family: "latoregular";
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 38, 40, 0.6);
  overflow: hidden;
}
.news-list a:hover,
.news-list a:focus {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.1);
}
.news-list a .news-type {
  display: block;
  margin: 0px 0px 10px;
  color: #48a8c0;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}
.news-list a h3 {
  margin: 0px;
  color: #ffffff;
  font-size: 26px;
  line-height: 32px;
}

/* testimonial section and carousel that makes it work */
.testimonial blockquote {
  padding: 0;
}

.testimonial .author-image img {
  width: 70px;
}

.heading-serif,
.heading,
.testimonial-section .heading,
.slider-section .heading,
.blog-post-entry .heading {
  font-size: 30px;
  font-family: "Playfair Display", times, serif;
  font-weight: 700;
}
.slider-item {
  padding: 0 20px;
  text-align: center;
  /* background: #ff0000; */
}

.owl-carousel.js-carousel-2 .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 299;
}
.owl-carousel.js-carousel-2 .owl-nav .owl-prev,
.owl-carousel.js-carousel-2 .owl-nav .owl-next {
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-top: -10px;
}
.owl-carousel.js-carousel-2 .owl-nav .owl-prev:hover,
.owl-carousel.js-carousel-2 .owl-nav .owl-prev:focus,
.owl-carousel.js-carousel-2 .owl-nav .owl-prev:active,
.owl-carousel.js-carousel-2 .owl-nav .owl-next:hover,
.owl-carousel.js-carousel-2 .owl-nav .owl-next:focus,
.owl-carousel.js-carousel-2 .owl-nav .owl-next:active {
  outline: none;
}
.owl-carousel.js-carousel-2 .owl-nav .owl-prev span:before,
.owl-carousel.js-carousel-2 .owl-nav .owl-next span:before {
  font-size: 40px;
}
.owl-carousel.js-carousel-2 .owl-nav .owl-prev {
  left: -30px !important;
}
.owl-carousel.js-carousel-2 .owl-nav .owl-next {
  right: -30px !important;
}

.owl-carousel.js-carousel-2 .owl-dots .owl-dot {
  display: inline-block;
  border: none;
}
.owl-carousel.js-carousel-2 .owl-dots .owl-dot > span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #e9ecef;
  display: inline-block;
}
.owl-carousel.js-carousel-2 .owl-dots .owl-dot.active > span {
  background: #ff0000;
}
.owl-carousel.js-carousel-2 .owl-dots .owl-dot:active,
.owl-carousel.js-carousel-2 .owl-dots .owl-dot:focus,
.owl-carousel.js-carousel-2 .owl-dots .owl-dot:hover {
  outline: none;
}

.owl-custom-nav {
  float: right;
  position: relative;
  z-index: 10;
}
.owl-custom-nav .owl-custom-prev,
.owl-custom-nav .owl-custom-next {
  padding: 10px;
  font-size: 30px;
  background: #ccc;
  line-height: 0;
  width: 60px;
  text-align: center;
  display: inline-block;
}

footer {
  position: relative;
  z-index: inherit;
}
footer {
  /* position: absolute; */
  padding: 13px 0;
  width: 100%;
  bottom: 0;
  font-size: 12px;
  z-index: 999;
}
footer a {
  color: #fff;
}
footer span.footerlogo {
  display: inline-block;
}
footer span.footerlogo img {
  max-width: 196px;
  width: 100%;
  margin-bottom: -1px;
}
footer span.copyRight {
  font-family: "latoregular";
  font-weight: normal;
}
footer samp {
  padding: 0 10px;
}

footer .wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  flex-direction: row-reverse;
  min-height: 30px;
}
footer .left {
  width: calc(100% - 200px);
  max-width: 580px;
  line-height: 15px;
  text-align: left;
}
.social-icons ul {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 0;
}
footer .info-heading {
  font-family: Ne10-simplified, "Helvetica Neue", Helvetica, Roboto, Arial,
    sans-serif;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(72, 93, 101, 0.3);
  padding-bottom: 18px;
  margin-top: 12px;
}
footer h3 {
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
}
.company-info-nav-list ul li a {
  display: block;
  color: #ff48c5;
  padding: 12px 0;
  border-top: 1px solid rgba(72, 93, 101, 0.3);
}
.social-icons ul li:first-child {
  margin-left: 0;
}
.social-icons ul li:last-child {
  margin-right: 0;
}
.social-icons ul li {
  margin: 0 13px;
}
.social-icons ul li a {
  opacity: 0.5;
  display: block;
}
.social-icons ul li img {
  vertical-align: middle;
}
.social-icons ul li:hover a {
  opacity: 1;
}

.footer-section {
  padding: 10px 25px 30px 25px;
  /* height: 120px; */
  background: #0d4b4e;
  font-size: 13px;
}
.social {
  background: #000;
  color: #fff;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}

.whatsapp {
  background-color: #363b4d;
}

blockquote {
  font-size: 14px;
  border-left: 5px solid #d90000;
}
blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}

.mapouter {
  position: relative;
  text-align: right;
  height: 300px;
  width: 100%;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 300px;
  width: 100%;
}
